import React from "react";
import clsx from "clsx";

interface HorizontalNavigationProps {
  isNotLast: boolean;
  show: boolean;
}

export const HorizontalNavigation: React.FC<HorizontalNavigationProps> = ({
  isNotLast,
  show,
}) => {
  return (
    <>
      {show && (
        <div className="nav__horizontal">
          <ul>
            <li>
              <p>villa</p>
            </li>
            <li>
              <p>jet</p>
            </li>
            <li>
              <p>heli</p>
            </li>
            <li>
              <p>yacht</p>
            </li>
            <li>
              <p>car</p>
            </li>
          </ul>
        </div>
      )}

      {isNotLast && (
        <svg className={clsx("arrow-scroll", {})}>
          <path className="a1" d="M0 0 L20 16 L40 0"></path>
          <path className="a2" d="M0 20 L20 36 L40 20"></path>
        </svg>
      )}
    </>
  );
};
