import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { Swiper, SwiperSlide } from "swiper/react";
import logo from "../assets/logo_full.png";
import mission from "../assets/mission.jpg";
import team from "../assets/team.jpg";
import screen from "../assets/screen.png";
import screen_tablet from "../assets/screen_tablet.png";
import arrow from "../assets/simple_arrow.svg";
import SwiperCore, { Mousewheel, Navigation, Pagination } from "swiper";
SwiperCore.use([Navigation, Pagination, Mousewheel]);

interface AboutUsProps {
  aboutUsOpen: boolean;
  isMobile: boolean;
}

export const AboutUs: React.FC<AboutUsProps> = ({ aboutUsOpen, isMobile }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [currentTitle, setCurrentTitle] = useState("");
  const [lastSlide, setLastSlide] = useState(0);
  const [swiper, setSwiper] = useState<SwiperCore | null>(null);

  useEffect(() => {
    if (aboutUsOpen) swiper?.enable();
    else swiper?.disable();
  }, [aboutUsOpen]);

  useEffect(() => {
    if (!isMobile) {
      switch (currentSlide) {
        case 0:
          setCurrentTitle("");
          break;
        case 1:
          setCurrentTitle("Our mission");
          break;
        case 2:
          setCurrentTitle("Application");
          break;
        case 3:
          setCurrentTitle("Our vision");
          break;
        case 4:
          setCurrentTitle("Our values");
          break;
        case 5:
          setCurrentTitle("Our team");
          break;
        default:
          setCurrentTitle("");
      }
    } else {
      switch (currentSlide) {
        case 0:
        case 4:
          setCurrentTitle("");
          break;
        case 1:
          setCurrentTitle("Our mission");
          break;
        case 2:
          setCurrentTitle("Application");
          break;
        case 3:
          setCurrentTitle("Our vision");
          break;
        case 5:
          setCurrentTitle("Our values");
          break;
        case 6:
          setCurrentTitle("Our team");
          break;
        default:
          setCurrentTitle("");
      }
    }
  }, [currentSlide, isMobile]);

  return (
    <div
      className={clsx("about__wrapper", {
        ["visible"]: aboutUsOpen,
      })}
    >
      <Swiper
        className="mh-50 oh"
        mousewheel
        direction={"vertical"}
        slidesPerView={1}
        speed={800}
        autoHeight
        initialSlide={0}
        onInit={(swiper) => {
          setLastSlide(swiper.slides.length - 1);
          setSwiper(swiper);
        }}
        onSlideChange={(swiper) => {
          setCurrentSlide(swiper.realIndex);
        }}
      >
        <SwiperSlide className="aboutSwiper__slide">
          <div
            className={clsx("dark", {
              ["half-col"]: !isMobile,
              ["one-third-col"]: isMobile,
            })}
          >
            <img src={logo} id="aboutUslogo" />
          </div>
          <div
            className={clsx("light", {
              ["half-col"]: !isMobile,
              ["two-third-col"]: isMobile,
            })}
          >
            <h1>About us</h1>
            <p>
              Esqop is a collaborative platform for luxury lifestyle managers.
              It is a marketplace where travel experts from superyacht, aviation
              and hospitality industries can meet and collaborate with each
              other across industries in a seamless and interactive manner for
              the benefit of their high net-worth clientele.
            </p>
            <p>
              In its core Esqop is all about innovation, simplicity and finding
              creative and smart ways to be more efficient.
            </p>
          </div>
        </SwiperSlide>
        <SwiperSlide className="aboutSwiper__slide">
          <div className="full light">
            <h6>
              Simplify and automate to make the day of a luxury lifestyle
              manager more efficient and productive.
            </h6>
          </div>
        </SwiperSlide>
        <SwiperSlide className="aboutSwiper__slide">
          <div className="full light">
            {isMobile ? (
              <img src={screen_tablet} id="tablet_screen" />
            ) : (
              <img src={screen} id="desktop_screen" />
            )}
          </div>
        </SwiperSlide>
        <SwiperSlide className="aboutSwiper__slide">
          <div className="full dark">
            <h6>
              Become the first choice among luxury lifestyle managers when it
              comes to collaboration within industry and travel experts from
              other industries.
            </h6>
          </div>
        </SwiperSlide>
        {isMobile && (
          <SwiperSlide className="aboutSwiper__slide">
            <div className="full mobile_bg">
              <img src={mission} id="full_img_bg" />
              <h1>Our values</h1>
            </div>
          </SwiperSlide>
        )}
        <SwiperSlide className="aboutSwiper__slide">
          <div
            className={clsx("light", {
              ["half-col"]: !isMobile,
              ["full-col"]: isMobile,
            })}
          >
            {!isMobile && <h1>Our values</h1>}
            <p>
              <span style={{ fontWeight: "bold" }}>Trust</span> <br />
              We are dedicated to building long-term and solid relationships
              with our users and partners. We do not take any shortcuts. We
              value our users time and trust and go to great lengths to meet
              their needs in terms of unmatched user experience and efficiency.
              We are fully aware that the path to our success lies through the
              success of our partners. We are dedicated to constantly improving
              the user experience for our partners.
            </p>
            <p>
              <span style={{ fontWeight: "bold" }}>Integrity</span> <br />
              We are professional in the way we speak among us in the company,
              with our clients, partners, with our competitors and neighbors. We
              are highly competent in each of our areas of expertise and always
              keep ourselves up to date with the technology development to apply
              it for the best of our partners, industry and the whole world.
            </p>
          </div>
          {!isMobile && (
            <div className="half-col dark">
              <img src={mission} className="bg_img boat" />
            </div>
          )}
        </SwiperSlide>
        <SwiperSlide className="aboutSwiper__slide">
          <div
            className={clsx("light", {
              ["half-col"]: !isMobile,
              ["one-third-col"]: isMobile,
            })}
          >
            <img src={team} className="bg_img team" />
          </div>
          <div
            className={clsx("dark", {
              ["half-col"]: !isMobile,
              ["two-third-col"]: isMobile,
            })}
          >
            <h1>Our team</h1>
            <p>
              The team of Esqop consists of highly qualified and motivated
              business and software developers. Our goal is to apply our
              knowledge and experience to make collaboration and interaction in
              the luxury space as efficient and productive as possible.
            </p>
          </div>
        </SwiperSlide>
      </Swiper>

      <img
        src={arrow}
        className={clsx("aboutUs_arrow", {
          ["hide"]: lastSlide === currentSlide,
        })}
      />

      <h6 className="title">{currentTitle}</h6>
    </div>
  );
};
