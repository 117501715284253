import { Box } from "@react-three/drei";
import { Canvas } from "@react-three/fiber";
import React, { Suspense } from "react";
import Model from "../Model";

interface SceneProps {
  isMobile: boolean;
}

export const Scene: React.FC<SceneProps> = ({ isMobile }) => {
  return (
    <div className="model__wrapper">
      <Canvas>
        <Suspense fallback={null}>
          <ambientLight intensity={1.2} color="#dddedf" />

          <Model
            scale={isMobile ? 0.07 : 0.15}
            isMobile={isMobile}
            position={isMobile ? [-1.4, 3, 0] : [-3, 3, 0]}
          />
        </Suspense>
      </Canvas>
    </div>
  );
};
