import clsx from "clsx";
import React, { useEffect, useState } from "react";
import SwiperCore, { Mousewheel, Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import { default as logo } from "./assets/logo_title.svg";
import { default as logoWhite } from "./assets/logo_title_white.svg";
import yacht1 from "./assets/yacht1.png";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import yacht2 from "./assets/yacht2.png";
import note8 from "./assets/note8.png";
import screen from "./assets/screen2.png";
import car4 from "./assets/car4.png";
import car6 from "./assets/car6.png";
import car7 from "./assets/car7.png";
import yacht5 from "./assets/yacht5.png";
import symbol from "./assets/symbol.png";
import jet3 from "./assets/jet3.png";
import {
  HorizontalNavigation,
  Scene,
  VerticalNavigation,
  AboutUs,
} from "./components";
import useIsMobile from "./utils/useIsMobile";
SwiperCore.use([Navigation, Pagination, Mousewheel]);

export default function App() {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [lastSlide, setLastSlide] = useState(0);
  const [swiper, setSwiper] = useState<SwiperCore | null>(null);
  const isMobile = useIsMobile();
  const [aboutUsOpen, setAboutUsOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const toggleAboutUs = () => setAboutUsOpen(!aboutUsOpen);

  useEffect(() => {
    if (aboutUsOpen) swiper?.disable();
    else swiper?.enable();
  }, [aboutUsOpen, swiper]);

  const isValidEmail = (email: string) => {
    var pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );
    return pattern.test(email);
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setSuccess(false);
    if (!isValidEmail(email)) {
      setError("A valid email address is required");
      return;
    }

    try {
      setLoading(true);
      const response = await fetch(
        "https://esqop-mail-server.herokuapp.com/send-mail",
        // "http://localhost:5555/send-mail",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email }),
        }
      );

      if (response.status === 200) {
        setEmail("");
        setSuccess(true);
      } else {
        setError("Something went wrong... Try again later");
      }
    } catch (err) {
      setError("Something went wrong... Try again later");
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e: any) => {
    if (error) setError(null);
    setEmail(e.target.value);
  };

  return (
    <div className="App">
      <HorizontalNavigation
        // show={currentSlide !== 0}
        show
        isNotLast={currentSlide !== lastSlide}
      />
      <VerticalNavigation
        onClick={() => swiper?.slideTo(lastSlide, 300)}
        goBack={() => swiper?.slideTo(0, 0)}
        show={currentSlide !== 0}
        isLast={currentSlide === lastSlide}
        toggleAboutUs={toggleAboutUs}
        aboutUsOpen={aboutUsOpen}
      />

      <AboutUs isMobile={isMobile} aboutUsOpen={aboutUsOpen} />

      <div
        className={clsx(
          "slider__wrapper"
          // {
          //   ["home"]: currentSlide === 0,
          // }
        )}
      >
        <div className="slider__bg">
          <img
            src={yacht1}
            className={clsx({ ["visible"]: currentSlide === 0 })}
          />
          <img
            src={yacht2}
            className={clsx({ ["visible"]: currentSlide === 1 })}
          />
          <img
            src={screen}
            className={clsx({ ["visible"]: currentSlide === 2 })}
          />
          <img
            src={yacht5}
            className={clsx({ ["visible"]: currentSlide === 3 })}
          />
          {/* <img
            src={yacht5}
            className={clsx({ ["visible"]: currentSlide === 4 })}
          /> */}
          {/* <img
            src={car6}
            className={clsx({ ["visible"]: currentSlide === 5 })}
          /> */}
          {/* <img
            src={car7}
            className={clsx({ ["visible"]: currentSlide === 6 })}
          /> */}
          {/* <img
            src={note8}
            className={clsx({ ["visible"]: currentSlide === 7 })}
          /> */}
          <img
            src={symbol}
            className={clsx({ ["visible"]: currentSlide === lastSlide })}
          />
        </div>

        <Swiper
          className="swiper__root mh-50 oh"
          mousewheel
          direction={"vertical"}
          slidesPerView={1}
          speed={1000}
          autoHeight
          initialSlide={0}
          onInit={(swiper) => {
            setLastSlide(swiper.slides.length - 1);
            setSwiper(swiper);
          }}
          slideToClickedSlide={false}
          onSlideChange={(swiper) => {
            setCurrentSlide(swiper.realIndex);
          }}
        >
          {/* <SwiperSlide className="mySwiper__slide">
            {currentSlide === 0 && <Scene isMobile={isMobile} />}
            <div
              className={clsx("main__wrapper", {
                ["hidden"]: currentSlide !== 0,
              })}
            >
              <img src={logo} alt="esqop" />
              <p>Developed for you with your client in mind...</p>
            </div>
          </SwiperSlide> */}
          <SwiperSlide className="mySwiper__slide">
            <div className="mySwiper__wrapper">
              <h1>Cross-industry collaboration</h1>
              <p>
                How many emails do you have to send back and forth before you
                can confirm to your client that the superyacht, jet or villa is
                available? What about all that time that goes on finding the
                right partners and then on managing the administrative part of
                the relationship with them?
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide className="mySwiper__slide">
            <div className="mySwiper__wrapper">
              <h1>Integrate and automate </h1>
              <p>
                The fine art of planning and executing a luxury vacation or a
                business trip is highly detail-oriented project. Nothing can be
                left to chance and the pressure is always on. But what if you
                could take a significant amount of that pressure away? There is
                a huge potential for improvement in the way the interaction
                between lifestyle managers is done today and at the same time
                increasing yours and your partner’s sales.
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide className="mySwiper__slide">
            <div className={`mySwiper__wrapper  dark`}>
              <h1>Collaborate and grow</h1>
              <p>
                Esqop is a one-stop shop where both your new clients and
                partners can find you and engage with you in an interactive and
                frictionless manner. Collaborate and grow together with your new
                partners whether it is a superyacht charter, luxury villa
                booking or arranging a private jet for a client. Find the best
                and work with the best from the industry and do it in a much
                more efficient way with Esqop.
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide className="mySwiper__slide">
            <div className="mySwiper__wrapper">
              <h1>Want to know more?</h1>
              <p>
                Leave your email to learn more about Esqop and be the first to
                know about the launch!
              </p>
              <form style={{ marginTop: "20px" }}>
                <div className="textfield">
                  <input
                    placeholder={"Your Email"}
                    value={email}
                    onChange={handleChange}
                  />
                  {loading ? (
                    <div className="loader loader--style2">
                      <svg
                        version="1.1"
                        id="loader-1"
                        xmlns="http://www.w3.org/2000/svg"
                        x="0px"
                        y="0px"
                        width="40px"
                        height="40px"
                        viewBox="0 0 50 50"
                      >
                        <path
                          fill="#000"
                          d="M25.251,6.461c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615V6.461z"
                        >
                          <animateTransform
                            attributeType="xml"
                            attributeName="transform"
                            type="rotate"
                            from="0 25 25"
                            to="360 25 25"
                            dur="0.6s"
                            repeatCount="indefinite"
                          />
                        </path>
                      </svg>
                    </div>
                  ) : (
                    <button
                      type="submit"
                      disabled={loading}
                      onClick={(e) => handleSubmit(e)}
                    >
                      <HiOutlineArrowNarrowRight />
                    </button>
                  )}
                </div>
                {success && (
                  <p className="form__message" style={{ marginTop: "20px" }}>
                    Thank you for the interest! We will contact you shortly.
                  </p>
                )}
                {error && <p className="form__error_message">{error}</p>}
              </form>
            </div>
          </SwiperSlide>
          {/* <SwiperSlide className="mySwiper__slide">
            <div className="mySwiper__wrapper">
              <h1>Esqop’s Partners</h1>
              <p>
                Our primary partners are Yacht and Aircraft Charter Experts,
                Travel Designers, Management Companies (Yacht, Villa, Aircraft)
                and companies offering luxury Car rentals.
              </p>
            </div>
          </SwiperSlide> */}
          {/* <SwiperSlide className="mySwiper__slide">
            <div className="mySwiper__wrapper">
              <h1>PA | Family Office | High-End Traveler</h1>
              <ul>
                <li>
                  Global choice of luxury assets to cater your client’s needs{" "}
                </li>
                <li>All assets are handpicked by the industry’s top experts</li>
                <li>
                  Every expert associated with an asset has personally inspected
                  it
                </li>
                <li>Live availability check and instant booking</li>
                <li>Reduced hassle and more time efficient</li>
                <li>
                  Industry’s top professionals supporting you the whole journey
                </li>
              </ul>
            </div>
          </SwiperSlide> */}
          {/* <SwiperSlide className="mySwiper__slide">
            <div className="mySwiper__wrapper">
              <h1>Management Company</h1>
              <ul>
                <li>Greater global market exposure of your assets</li>
                <li>Additional sales at zero cost</li>
                <li>Electronic contract </li>
                <li>No more time waste on unqualified leads </li>
                <li>Paid and organic marketing of your assets at zero cost</li>
              </ul>
            </div>
          </SwiperSlide> */}
          {/* <SwiperSlide className="mySwiper__slide">
            <div className="mySwiper__wrapper">
              <h1>Travel Experts</h1>
              <ul>
                <li>X-sell/Up-sell opportunity</li>
                <li>Best commission structure on the market</li>
                <li>Same-day commission</li>
                <li>Additional sales at zero cost</li>
                <li>Live availability check and instant booking</li>
                <li>Reduced hassle and more time efficient </li>
              </ul>
            </div>
          </SwiperSlide> */}
          <SwiperSlide className="mySwiper__slide">
            <div className="mySwiper__wrapper contacts ">
              <img src={logoWhite} alt="esqop" />
              <h6>Developed for you with your client in mind.</h6>
              <ul>
                <li>
                  <div>
                    <p>Email</p>
                  </div>
                  <div>
                    <a href="mailto:info@esqop.com">info@esqop.com</a>
                  </div>
                </li>
                <li>
                  <div>
                    <p>Phone</p>
                  </div>
                  <div>
                    <a href="callto:+4740090663">+47 400 90 663</a>
                  </div>
                </li>
                {/* <li>
                  <div>
                    <p>Facebook</p>
                  </div>
                  <div>
                    <a target="_blank" href="https://www.facebook.com/esqop">
                      fb.com/esqop
                    </a>
                  </div>
                </li> */}
                {/* <li>
                  <div>
                    <p>LinkedIn</p>
                  </div>
                  <div>
                    <a
                      target="_blank"
                      href="https://www.linkedin.com/company/esqop"
                    >
                      linkedin.com/company/esqop
                    </a>
                  </div>
                </li> */}
                <li>
                  <div>
                    <p>Address</p>
                  </div>
                  <div>
                    <a href="#">
                      Hasleveien 28B <br /> 0571 Oslo <br /> Kingdom of Norway
                    </a>
                  </div>
                </li>
              </ul>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
}
