import React from "react";
import clsx from "clsx";

interface VerticalNavigationProps {
  show: boolean;
  onClick: () => void;
  goBack: () => void;
  toggleAboutUs: () => void;
  aboutUsOpen: boolean;
  isLast: boolean;
  // aboutUsColor: string | undefined;
}

export const VerticalNavigation: React.FC<VerticalNavigationProps> = ({
  show,
  onClick,
  toggleAboutUs,
  aboutUsOpen,
  isLast,
  goBack,
  // aboutUsColor,
}) => {
  return (
    <div className="nav__vertical">
      <ul>
        {!aboutUsOpen && (
          <>
            {isLast && (
              <li onClick={goBack}>
                <p>Back to Top</p>
              </li>
            )}
            <li onClick={onClick}>
              <p>Contact</p>
            </li>
          </>
        )}

        <li
          onClick={toggleAboutUs}
          // style={
          //   aboutUsColor
          //     ? {
          //         color: "red",
          //       }
          //     : {}
          // }
        >
          {aboutUsOpen ? <p>Close</p> : <p>About us</p>}
        </li>
      </ul>
    </div>
  );
};
