import { useGLTF } from "@react-three/drei";
import { useFrame, useLoader } from "@react-three/fiber";
import React, { useRef, useState } from "react";
import * as THREE from "three";
import { GLTF } from "three/examples/jsm/loaders/GLTFLoader";
var lerp = require("lerp");

type GLTFResult = GLTF & {
  nodes: {
    Shape1: THREE.Mesh;
  };
  materials: {
    DefaultMaterial: THREE.MeshStandardMaterial;
  };
};

export default function Model(props: any) {
  const group = useRef();
  const group2 = useRef();
  const { nodes } = useGLTF("/assets/logo.glb") as unknown as GLTFResult;

  return (
    <group ref={group} rotation={[0, 0, 0]} {...props} dispose={null}>
      <mesh
        castShadow
        ref={group2}
        receiveShadow
        geometry={nodes.Shape1.geometry}
        material={
          new THREE.MeshStandardMaterial({
            ...(props.isMobile
              ? {
                  metalness: 0.5,
                  color: new THREE.Color("#d9e0e4"),
                }
              : {}),
            transparent: false,
          })
        }
      />

      <ActionLights isMobile={props.isMobile} />
    </group>
  );
}

function ActionLights({ isMobile }: { isMobile: boolean }) {
  const front = useRef();

  useFrame(({ mouse }) => {
    if (isMobile) return;

    const distXY = Math.abs(mouse.x) + Math.abs(mouse.y);
    // @ts-ignore
    front.current.position.x = lerp(0, Math.abs(mouse.x) * 0.5, 100);
    // @ts-ignore
    front.current.position.y = lerp(0, Math.abs(mouse.y) * 0.8, 100);

    // @ts-ignore
    // front.current.position.z = lerp(0, (Math.PI / mouse.x) * 0.2, 100);
  });

  return (
    <>
      <spotLight
        castShadow
        ref={front}
        penumbra={1}
        angle={Math.PI / 3}
        position={
          isMobile
            ? [9.369458128078817, 14.147783251231527, 8.82167487684729]
            : [0, 0, 40]
        }
        distance={25}
        intensity={1}
        shadow-mapSize-width={1000}
        shadow-mapSize-height={100}
      />
    </>
  );
}

useGLTF.preload("/logo.glb");
